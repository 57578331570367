@import "themes/constants.module.scss";

.root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	.main {
		width: 100%;
		max-width: 1440px;
		overflow: hidden;

		margin-left: auto;
		margin-right: auto;
		margin-top: 112px;

		@media screen and (max-width: $screen-md) {
			margin-top: 64px;
		}

		@media screen and (max-width: $screen-sm) {
			margin-top: 48px;
		}

		.content {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 40px;

			padding: 0 64px;
			@media screen and (max-width: $screen-md) {
				padding: 0 24px;
			}

			@media screen and (max-width: $screen-sm) {
				margin: 0 16px;
			}

			.header-container {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 32px;

				.box {
					padding: 12px 16px;
					border-radius: 4px;
					border: 1px solid var(--Secondary-500);
					width: fit-content;
				}

				.header {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					align-self: center;
					gap: 48px;
					text-align: center;

					@media screen and (max-width: $screen-sm) {
						text-align: start;
					}

					.secondary-title {
						font-size: inherit;
					}

					.header-content {
						display: flex;
						flex-direction: column;
						justify-content: center;
						gap: 24px;

						.project-call-container {
							display: flex;
							justify-content: center;
							gap: 24px;
							@media screen and (max-width: $screen-md) {
								flex-direction: column;
								align-items: center;
							}
						}

						.description {
							display: grid;
							grid-gap: 24px;
							grid-template-rows: auto;
							grid-template-columns: repeat(2, 1fr);
							max-width: 1440px;

							@media screen and (max-width: $screen-md) {
								flex-direction: column;
								display: flex;
							}
						}
					}
				}

				.separator {
					width: 302px;
					border-bottom: 2px solid var(--Neutral-300);
				}
			}

			.types {
				display: flex;
				flex-wrap: wrap;
				gap: 24px;
				.types-name {
					display: flex;
					gap: 16px;
					border: 2px solid #6b7280;
					border-radius: 32px;
					padding: 16px;
				}
			}
		}
	}

	.form {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		gap: 32px;
		background: var(--color-background-200);
		padding: 64px 0;

		margin-top: 112px;
		@media screen and (max-width: $screen-md) {
			margin-top: 90px;
		}

		.form-container {
			width: 100%;
			max-width: 650px;
			display: flex;
			flex-direction: column;
			gap: 32px;
			margin: 0 auto 80px;

			.input-container {
				.input-row-wrapper {
					display: flex;
					gap: 24px;
				}
				display: flex;
				flex-direction: column;
				gap: 24px;

				.input {
					width: 100%;
				}

				.textarea-container {
					width: 100%;

					textarea {
						resize: vertical;
					}
				}
			}
		}
	}
}
