.root {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;

    .backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        overflow: hidden;
        z-index: 5;
    }

    .modal {
        position: relative;
        //max-width: 468px;
        background: var(--Generic-white, #FFF);
        padding: 32px 104px;
        //text-align: center;
        z-index: 5;
    }
}
