@import "themes/constants.module.scss";

.root {
	display: flex;
	flex-direction: column;
	gap: 40px;

	.title-container {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	.content {
		display: flex;
		flex-direction: column;
		gap: 48px;

		.confirmation-code {
			margin: 0 auto;
		}

		.button-container {
			display: flex;
			flex-direction: column;
			gap: 24px;
		}
	}
}
