@import "themes/constants.module.scss";

.root {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    // padding: 32px 0;
    // margin: 0 -5%;
    overflow: hidden;

  .dot-pulse {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #333; /* Color of the dots */
    animation: pulse 1.5s infinite linear; /* Adjust animation duration as needed */
    margin: 0 2px; /* Adjust spacing between dots */
  }

.dot-pulse:nth-child(2) {
  animation-delay: 0.25s; /* Delay for the second dot */
}

.dot-pulse:nth-child(3) {
  animation-delay: 0.5s; /* Delay for the third dot */
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0.7;
  }
}
}
