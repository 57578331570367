.root {
	display: flex;
	flex-direction: column;
	gap: 16px;

	.title-container {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	.themes {
		display: flex;
		flex-wrap: wrap;
		gap: 16px;
	}
}
