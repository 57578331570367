.root {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: fit-content;

	.select {
		font-family: "Space Grotesk";
		padding: 12px;
		border: 1px solid var(--color-generic-black);
		background: var(--color-generic-white);
		width: 100%;

		.option {
			width: 100px;
			height: 100px;
		}
	}

	.additional-info {
		color: #939393;
		line-height: 22px;
		letter-spacing: 0.5px;
	}
}
