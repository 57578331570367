@import "themes/constants.module.scss";

.root {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
	max-width: 644px;
	padding: 48px;
	border-radius: 16px;
	background: var(--color-background-200);
	flex: 1;
}
