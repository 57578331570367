@import "themes/constants.module.scss";

.root {
	cursor: pointer;

	&:nth-child(odd) {
		background-color: #f9fafb;
	}

	.options {
		width: 20px;
		padding: 0 16px;
	}

	.td {
		text-align: left;
		min-width: 160px;
		width: 160px;
		padding: 28px 24px;

		@media screen and (max-width: $screen-lg) {
			display: none;
		}

		.status-row {
			display: flex;
			width: 100%;
		}
	}
	.td-name {
		text-align: left;
		min-width: 160px;
		width: 160px;
		padding: 28px 24px;
		@media screen and (max-width: $screen-md) {
			padding: 16px;
			min-width: 310px;
			width: 100%;
		}
		@media screen and (min-width: $screen-md) and (max-width: $screen-lg) {
			padding: 16px;
			min-width: 679px;
			width: 100%;
		}
		.responsive {
			display: none;
			@media screen and (max-width: $screen-lg) {
				display: flex;
				flex-wrap: wrap;
				gap: 8px;
				padding: 8px 0px;
			}
		}

		.menu {
			position: fixed !important; // Not the best solution
			width: 231px;
			border-radius: 8px !important; // Not the best solution
		}

		.link {
			color: inherit;
		}
	}
}
