.root {
	display: flex;
	flex-direction: column;
	gap: 32px;

	.title-container {
		display: flex;
		flex-direction: column;
		gap: 24px;

		.secondary-title {
			font-size: inherit;
			color: var(--Secondary-500);
		}
	}

	.form-container {
		display: flex;
		flex-direction: column;
		gap: 24px;

		.input {
			width: 100%;
		}
		.input-content {
			border: 1px solid var(--color-generic-black);
		}
	}
}
