@import "themes/constants.module.scss";

.root {
	display: flex;
	flex-direction: column;
	gap: 24px;

	.title-container {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	.form-container {
		display: flex;
		flex-direction: column;
		gap: 16px;

		.input-container {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}
		.input-content {
			border: 1px solid var(--color-generic-black);
		}

		.register-redirect-container {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 6px;
		}
	}
}
