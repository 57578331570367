@import "themes/constants.module.scss";

.root {
	display: flex;
	flex-direction: column;
	padding: 0 64px 64px 64px;

	@media screen and (max-width: $screen-md) {
		padding: 0px 24px 64px 24px;
	}

	@media screen and (max-width: $screen-sm) {
		padding: 0px 16px 64px 16px;
	}

	.header {
		padding: 112px 64px;
		display: flex;
		flex-direction: column;
		gap: 24px;
		text-align: center;
	}

	.body {
		display: flex;
		flex-direction: column;
		gap: 48px;
		max-width: 768px;
		margin: 0 auto;

		.block {
			display: flex;
			flex-direction: column;
			gap: 36px;
		}
	}
}
