@import "themes/constants.module.scss";

.root {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 24px;
	
	@media screen and (max-width: $screen-sm) {
		justify-content: center;
	}

	@media screen and (max-width: $screen-xs) {
		flex-direction: column;
	}
	.input-container {
		max-width: 380px;
		width: 100%;
		@media screen and (max-width: $screen-xs) {
			width: 100%;
		}
		
	}

	.button-container {
		padding: 3px 0;
	}

	.input-primary {
		border-radius: 4px;
		border: 1px solid var(--color-generic-black);
	}
	.input-secondary {
		border-radius: 4px;
		border: 1px solid var(--color-generic-white);
		color: var(--color-generic-white);
	}
}

.modal {
	display: flex;
	flex-direction: column;
	padding: 24px 16px;
	width: fit-content;
	color: var(--color-generic-white);
	border-radius: 8px;
	background-color: var(--Primary-100);
}