@import "themes/constants.module.scss";

.root {
	.drawer {
		display: flex;
		flex-direction: column;
		gap: 32px;
		padding: 24px;
		position: fixed;
		top: 82px;
		right: 0;
		z-index: 4;
		max-width: 823px;
		width: 100%;
		height: 100vh;
		background: var(--color-background-200);
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
		transition: transform 0.3s ease-in-out;
		transform: translateX(101%);
		overflow-y: auto;

		@media screen and (max-width: $screen-sm) {
			padding: 24px;
			height: calc(100vh - 67.5px);
			top: 67.5px;
		}

		&.open {
			transform: translateX(0);
		}

		.close-container {
			display: flex;
			justify-content: flex-end;

			@media screen and (max-width: $screen-sm) {
				position: sticky;
				top: 0;
			}

			.close-icon {
				width: 24px;
				height: 24px;
				cursor: pointer;
			}
		}

		.header {
			display: flex;
			gap: 24px;

			@media screen and (max-width: $screen-sm) {
				flex-direction: column;
			}

			.poster-container {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 12px;

				.poster {
					width: 250px;
					height: 340px;
					border-radius: 16px;
				}

				.missing-poster {
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 12px;
					color: var(--Secondary-500);
					width: 250px;
					height: 340px;
					border: 1px solid var(--Secondary-500);
				}
			}

			.info-container {
				display: flex;
				flex-direction: column;
				gap: 32px;

				.author-container {
					display: flex;
					flex-direction: column;
					gap: 16px;

					.author {
						display: flex;
						gap: 16px;

						.avatar {
							width: 40px;
							height: 40px;
							border-radius: 50%;
						}

						.user {
							display: flex;
							flex-direction: column;
						}
					}
				}

				.info {
					display: flex;
					flex-direction: column;
					gap: 16px;
					.status {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						gap: 8px;

						.status-description {
							color: #4B5563;
						}
					}
				}
			}
		}

		.body {
			display: flex;
			flex-direction: column;
			gap: 8px;
			max-width: 695px;
			word-wrap: break-word;
		}

		.social-title {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}

		.social-download {
			display: flex;
			gap: 16px;
			flex-wrap: wrap;
			align-items: flex-end;
			.button {
				&:hover {
					.button-text {
						color: var(--color-generic-black);
					}
				}
				.button-text {
					color: var(--color-generic-white);
				}
			}
		}

		.button-container {
			display: flex;
			gap: 24px;
			@media screen and (max-width: $screen-sm) {
				flex-direction: column;
				width: 100%;
				gap: 32px;
			}
			.download, .anchor {
				display: flex;
				flex-direction: flex-end;
				width: 432px;
				gap: 16px;
				@media screen and (max-width: $screen-sm) {
					flex-direction: column;
					width: 100%;
				}
			}
		}
	}

	.overlay {
		position: fixed;
		top: 0;
		right: 0;
		z-index: 2;
		width: 100%;
		height: 100vh;
		background: transparent;
	}
}
