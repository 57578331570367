.root {
	display: flex;
	flex-direction: column;
	gap: 8px;

	.input {
		font-family: "Space Grotesk";
		padding: 12px;
		background: inherit;
	}

	.additional-info {
		color: #939393;
		line-height: 22px;
		letter-spacing: 0.5px;
	}
}
