@import "themes/constants.module.scss";

.root {
	display: flex;
	gap: 120px;
	padding: 64px;

	@media screen and (max-width: $screen-md) {
		padding: 64px 24px;
	}

	@media screen and (max-width: $screen-xs) {
		padding: 64px 16px;
	}

	.content {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 40px;

		.title-container {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}

		.form-container {
			display: flex;
			flex-direction: column;
			gap: 56px;

			.inputs-container {
				display: flex;
				flex-direction: column;
				gap: 24px;

				.textarea {
					width: 100%;

					textarea {
						resize: vertical;
					}
				}

				.input,
				.select {
					width: 100%;
				}

				.input-content {
					border: 1px solid var(--color-generic-black);
				}
			}
		}
	}

	.design {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;

		@media screen and (max-width: $screen-md) {
			display: none;
		}

		.image {
			width: 329px;
			height: 358px;
		}
	}
}
