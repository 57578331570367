@import "themes/constants.module.scss";

.root {
	display: flex;
	align-items: center;
	gap: 16px;

	.avatar {
		width: 40px;
		height: 40px;
		min-width: 40px;
		min-height: 40px;
		border-radius: 50%;
		overflow: hidden;
	}
}
