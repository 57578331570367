@import "themes/constants.module.scss";

.root {
	padding: 90px 70px;
	display: flex;
	flex-direction: column;
	gap: 80px;

	@media screen and (max-width: $screen-md) {
		padding: 90px 24px;
	}

	@media screen and (max-width: $screen-sm) {
		padding: 64px 16px;
	}

	.title {
		max-width: 768px;

		.inside-title {
			font-size: inherit;
		}
	}

	.body-container {
		.frame-image {
			max-height: 74px;
		}

		.separator {
			width: 100%;
			height: 1px;
			background-color: #000;
		}
	}
}
