.root {
	display: flex;
	flex-direction: column;
	text-align: left;
	max-width: 600px;
	min-width: 288px;
	min-height: 395px;
	justify-content: space-between;
	z-index: 5;
	padding: 32px;
	border-radius: 16px;

	.anchor-modal{
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	.button-container{ 
		display: flex;
		gap : 16px;
		z-index: 5;
	}
}
