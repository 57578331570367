@import "themes/constants.module.scss";

.root {
	display: flex;
	flex-direction: column;
	gap: 32px;
	padding: 64px;

	@media screen and (max-width: $screen-md) {
		padding: 64px 24px;
		gap: 48px;
	}

	@media screen and (max-width: $screen-sm) {
		padding: 48px 16px;
		gap: 32px;
	}

	.frame-image {
		max-height: 74px;
	}

	.container {
		margin: 0 0px 64px 0px;
		display: flex;
		flex-direction: column;
		gap: 64px;
		height: 100%;

		.section-1 {
			display: flex;
			max-width: 1200px;
			justify-content: center;
			align-items: stretch;
			align-self: stretch;
			height: 485px;
			border-radius: 16px;
			background: var(--background-200, #fbf9f3);
			margin: 0 auto;

			@media screen and (max-width: $screen-md) {
				flex-direction: column-reverse;
				height: unset;
				border-radius: 16px;
			}

			.text {
				display: flex;
				flex-direction: column;
				max-width: 1200px;
				padding: 48px;
				align-items: flex-start;
				width: 50%;

				.description {
					margin-top: 24px;
					margin-bottom: 40px;
				}

				@media screen and (max-width: $screen-lg) {
					padding: 24px;
				}

				@media screen and (max-width: $screen-md) {
					width: 100%;
					padding: 48px;
				}

				@media screen and (max-width: $screen-sm) {
					padding: 24px;
				}

				@media screen and (max-width: $screen-xs) {
					padding: 16px;
				}
			}

			.img {
				flex-grow: 1;
				width: 50%;
				border-radius: 0px 16px 16px 0px;

				@media screen and (max-width: $screen-md) {
					width: 100%;
					border-radius: 16px 16px 0px 0px;
					height: 300px;
				}
			}
		}

		.section-2 {
			display: flex;
			max-width: 1200px;
			justify-content: center;
			align-items: flex-start;
			align-self: stretch;

			border-radius: 16px;
			background: var(--background-200, #fbf9f3);
			margin: 0 auto;

			@media screen and (max-width: $screen-md) {
				flex-direction: column;
			}

			.text {
				display: flex;
				flex-direction: column;
				max-width: 1200px;
				padding: 48px;
				align-items: flex-start;
				width: 50%;
				height: 485px;
				justify-content: center;

				.description {
					margin-top: 24px;
					padding-left: 25px;
				}

				@media screen and (max-width: $screen-md) {
					height: unset;
					width: 100%;
				}

				@media screen and (max-width: $screen-sm) {
					padding: 24px;
				}

				@media screen and (max-width: $screen-xs) {
					padding: 16px;
				}
			}

			.img {
				height: 485px;
				width: 50%;
				border-radius: 16px 0px 0px 16px;

				@media screen and (max-width: $screen-md) {
					width: 100%;
					border-radius: 16px 16px 0px 0px;
					height: 300px;
				}
			}
		}
	}

	.link {
		text-decoration: none;
	}
}
