@import "themes/constants.module.scss";

.root {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	gap: 32px;
	background: var(--color-background-200);
	padding: 64px 0;

	margin-top: 112px;
	@media screen and (max-width: $screen-md) {
		margin-top: 90px;
	}

	.form-container {
		width: 100%;
		max-width: 650px;
		display: flex;
		flex-direction: column;
		gap: 32px;
		margin: 0 auto 80px;

		.input-container {
			.input-row-wrapper {
				display: flex;
				gap: 24px;
			}
			display: flex;
			flex-direction: column;
			gap: 24px;

			.input {
				width: 100%;
			}

			.textarea-container {
				width: 100%;

				textarea {
					resize: vertical;
				}
			}
		}
	}
}
