@import "themes/constants.module.scss";

.root {
	display: flex;
	flex-direction: column;
	gap: 40px;

	.design-container {
		display: flex;
		gap: 24px;
		flex-wrap: wrap;

		@media screen and (max-width: $screen-md) {
			justify-content: center;
		}

		@media screen and (max-width: $screen-xs) {
			flex-direction: column;
			align-self: center;
			justify-content: center;
		}

		.design {
			width: 100%;
			max-width: 310px;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 16px;
			text-align: start;
			padding: 16px;
			border-radius: 32px;
			border: 1px solid #6b7280;

			.design-image {
				width: 136px;
				height: 136px;
			}
		}
	}
}
