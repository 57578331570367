.root {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	user-select: none;

    width: 46px;
    height: 46px;

	color: var(--Generic-white, #FFF);
}
