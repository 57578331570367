@import "themes/constants.module.scss";

.root {
	position: relative;
	padding: 40px 64px 64px 64px;
	margin-top: auto;
	background: #0a0a0a;
	text-align: center;

	@media screen and (max-width: $screen-md) {
		padding: 40px 32px 32px 32px;
	}

	@media screen and (max-width: $screen-sm) {
		padding: 40px 16px 16px 16px;
	}

	.design {
		position: absolute;
		top: -37px;
		left: 0;
		width: 100%;
	}

	.content {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 32px;

		.logo {
			width: 208px;
			height: 35px;
		}

		.contact-links-container {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			gap: 32px;

			.content {
				display: flex;
				flex-direction: column;
				gap: 8px;
				.text {
					color: var(--Secondary-500)
				}
				.social-icon {
					width: 100%;
					overflow: visible;
				}
			}
			.social-links-container {
				display: flex;
				gap: 32px;
				overflow: visible;
				img {
					object-fit: contain;
				}
			}
		}

		.suscribe-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			max-width: 536px;
			width: 100%;
		}

		.links-container {
			display: flex;
			align-items: center;
			gap: 32px;

			@media screen and (max-width: $screen-md) {
				flex-direction: column;
				gap: 16px;
			}

			.link {
				text-decoration: none;
			}
		}

		.text {
			color: white;
		}
	}
}
