.root {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 16px;

	.input {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 1px;
		height: 1px;
		opacity: 0;
		overflow: hidden;
		z-index: -1;
		pointer-events: none;
	}

	.drag-and-drop-container {
		padding: 24px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 8px;
		background-color: var(--color-background-200);
		border: 1px dashed black;
		border-width: 1px;
		cursor: pointer;

		&.drag-over {
			background-color: #faf7eb;
		}

		.accept {
			color: var(--text-secondary);
		}
	}
}
