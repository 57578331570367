@import "themes/constants.module.scss";

.root {
	.table {
		border-collapse: collapse;
		width: 100%;

		.thead {
			border-bottom: 1px solid black;

			.ellipsis{
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				width: inherit;
			}

			.th-name {
				text-align: left;
				padding: 16px 24px;
			}

			.th-ai-image {
				text-align: center;
				padding: 16px 24px;
				@media screen and (max-width: $screen-sm) {
					display: none;
				}
			}

			.th-proof {
				text-align: center;
				padding: 16px 24px;
			}

			.th-status {
				text-align: center;
				padding: 16px;
				@media screen and (max-width: $screen-md) {
					display: none;
				}
			}

			.th-date {
				text-align: left;
				padding: 16px;
				@media screen and (max-width: $screen-md) {
					display: none;
				}
			}
		}
	}
}
