@import "themes/constants.module.scss";

.root {
	&:nth-child(odd) {
		background-color: #f9fafb;
	}

	.icons {
		width: 22px;
		height: 22px;
	}

	.ellipsis {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		width: inherit;
	}

	.td-name {
		text-align: left;
		padding: 28px 24px;
		max-width: 200px;
		gap: 4px;
		cursor: pointer;
		.responsive {
			display: none;
			@media screen and (max-width: $screen-sm) {
				display: flex;
				gap: 8px;
				padding: 8px 0px;
			}
		}

		.link {
			color: inherit;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			width: inherit;
		}
	}

	.td-ai-image {
		text-align: center;
		padding: 28px 24px;
		gap: 4px;
		@media screen and (max-width: $screen-sm) {
			display: none;
		}
	}

	.td-proof {
		text-align: center;
		padding: 28px 24px;
		gap: 4px;
	}

	.td-status {
		text-align: center;
		padding: 16px;
		gap: 4px;
		@media screen and (max-width: $screen-sm) {
			display: none;
		}
	}

	.td-date {
		text-align: left;
		padding: 16px;
		gap: 4px;
		@media screen and (max-width: $screen-md) {
			display: none;
		}
	}

	.options {
		position: relative;
		width: 20px;
		padding: 0 16px;
	}

	.button {
		padding: 0;
	}
}

.anchor-modal {
	display: flex;
	flex-direction: column;
	text-align: left;
	max-width: 600px;
	min-width: 288px;
	min-height: 395px;
	justify-content: space-between;
	gap: 24px;

	.button-container{
		display: flex;
		gap : 16px;
	}
}
