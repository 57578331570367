@import "themes/constants.module.scss";

.root {
	display: flex;
	flex-direction: column;
	gap: 12px;

	.code-container {
		display: flex;
		align-items: center;
		gap: 24px;

		@media screen and (max-width: $screen-sm) {
			gap: 8px;
		}

		.input {
			width: 90.8px;
			height: 120px;
			padding: 25px 33px 25px 33px;
			border-radius: 8px;
			border: 1px solid #eaeff5;
			background-color: #f3f4f6;
			font-size: 40px;
			font-family: "Space Grotesk";

			@media screen and (max-width: $screen-sm) {
				font-size: 32px;
				width: 60px;
				height: 100px;
				padding: 15px 18px;
			}
		}
	}
}
