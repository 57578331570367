@import "themes/constants.module.scss";

.root {
	display: flex;
	border-radius: 16px;
	overflow: hidden;
	margin: 48px 0 64px 0;

	@media screen and (max-width: $screen-sm) {
		flex-direction: column;
	}

	.poster-container {
		display: flex;

		.poster-image {
			width: 330px;

			@media screen and (max-width: $screen-sm) {
				width: 100%;
			}
		}
	}

	.body-container {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 32px;
		min-height: 432px;
		padding: 32px 48px;
		background: rgba(0, 0, 0, 0.4);
		background-blend-mode: luminosity;
		border-radius: 0 16px 16px 0;

		@media screen and (max-width: $screen-md) {
			padding: 32px 24px;
		}

		@media screen and (max-width: $screen-sm) {
			border-radius: 0 0 16px 16px;
			padding: 24px 16px;
		}

		.background {
			position: absolute;
			z-index: -1;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 200%;
			height: 200%;
			filter: blur(128px);
		}

		.body {
			display: flex;
			flex-direction: column;
			gap: 16px;

			.header {
				display: flex;
				flex-direction: column;
				gap: 16px;

				.project-leader p {
					color: white;
				}

				.infos {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					justify-content: space-between;
					gap: 8px;

					.category {
						margin-left: 8px;
						padding: 4px 8px;
						background-color: var(--Secondary-900);
						border-radius: 8px;
						white-space: nowrap;
					}
				}
			}

			.content {
				display: flex;
				flex-direction: column;
				gap: 8px;
			}
		}
	}
}
