@import "themes/constants.module.scss";

.container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 32px 24px;

	@media (max-width: 768px) {
		padding: 32px 16px;
	}
	margin-bottom: 48px;
}

// Forum Header
.forum-header {
	background-color: #fbf9f3;
	border-radius: 16px;
	padding: 32px;
	@media (max-width: 640px) {
		padding: 32px 16px;
	}
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

	margin-bottom: 48px;

	&__icon {
		display: flex;
		justify-content: center;
		margin-bottom: 16px;
		color: #f9b403;
	}

	&__content {
		max-width: 700px;
		margin: 0 auto;
	}

	&__title {
		font-size: 2rem;
		font-weight: bold;
		margin-bottom: 24px;
	}

	&__characteristics {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		justify-content: flex-start;
		margin-bottom: 16px;

		&-item {
			display: inline-flex;
			align-items: center;
			gap: 4px;

			&-label {
				font-size: 0.875rem;
				font-weight: 500;
				color: #4b5563;
			}

			&-value {
				background-color: #ff75b7;
				color: #ffffff;
				padding: 4px 8px;
				border-radius: 8px;
				font-size: 0.875rem;
			}
		}
	}

  &__subject-title{
    font-weight: bold;
  }

  &__subject-container:not(:last-child) {
    border-bottom: 1px solid #65666a;
    padding-top: 16px;
    padding-bottom: 16px;
  }

	&__description {
		color: #4b5563;
		border-top: 1px solid #e5e7eb;
		padding-top: 16px;
		margin-top: 16px;
	}
}

// Comment Form
.comment-form {
	background-color: #ffffff;
	border-radius: 12px;
	padding: 16px;
	margin-bottom: 32px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
	width: 100%;
	box-sizing: border-box;

	@media (max-width: 768px) {
		padding: 16px;
	}

	&__title {
		font-size: 1.25rem;
		font-weight: 600;
		margin-bottom: 16px;
	}

	&__textarea {
		width: 100%;
		padding: 16px;
		border: 1px solid #e5e7eb;
		border-radius: 8px;
		resize: vertical;
		min-height: 100px;
		margin-bottom: 16px;
		box-sizing: border-box;

		&:focus {
			outline: none;
			border-color: #f9b403;
			box-shadow: 0 0 0 2px rgba(249, 180, 3, 0.1);
		}
	}

	&__actions {
		display: flex;
		gap: 16px;
		margin-top: 16px;

		@media (max-width: 640px) {
			flex-direction: column;
		}
	}

	&__media-button {
		background-color: rgba(31, 41, 55, 0.05);
		color: #4b5563;
		display: flex;
		align-items: center;
		gap: 4px;
		padding: 6px 12px;
		border-radius: 9999px;
		border: none;
		cursor: pointer;
		transition: background-color 0.2s ease;

		&:hover {
			background-color: rgba(31, 41, 55, 0.1);
		}
	}

	&__submit {
		// background: linear-gradient(to bottom, #ffd05c, #f9b403);
		// color: #1f2937;
		margin-left: auto;
		display: flex;
		// align-items: center;
		// gap: 4px;
		// padding: 10px 16px;
		// border-radius: 9999px;
		// border: none;
		// cursor: pointer;
		// transition: opacity 0.2s ease;

		// @media (max-width: 640px) {
			// margin-left: 0;
			// justify-content: center;
		// }

		// &:hover {
			// opacity: 0.9;
		// }
	}
}

// Comment Card
.comment-card {
	&__container {
		margin-left: 40px;
		padding-left: 40px;
		@media (max-width: 768px) {
			margin-left: 10px;
			padding-left: 10px;
		}
		border-left: 1px solid #e5e7eb;
	}

	&__content {
		background-color: #fbf9f3;
		border-radius: 16px;
		padding: 32px;
		margin-bottom: 8px;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
		transition: box-shadow 0.2s ease;

		&:hover {
			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
		}

		@media (max-width: 768px) {
			padding: 24px;
		}
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 8px;
	}

	&__user-name {
		font-weight: 500;
		margin-bottom: 4px;
	}

	&__user-timestamp {
		font-size: 0.875rem;
		color: #4b5563;
	}

	&__actions {
		display: flex;
		gap: 4px;
	}

	&__like-button {
		background-color: transparent;
		color: #f9b403;
		display: flex;
		align-items: center;
		gap: 4px;
		padding: 6px 12px;
		border-radius: 9999px;
		border: 1px solid #f9b403;
		cursor: pointer;
		transition: all 0.2s ease;

		svg {
			transition: fill 0.2s ease;
		}

		&--active {
			background: linear-gradient(to bottom, #ffd05c, #f9b403);
			color: #ffffff;
			border: none;

			svg {
				fill: #ffffff;
			}
		}
	}

	&__delete-button {
		color: #ef4444;
		background: none;
		border: none;
		cursor: pointer;
		padding: 4px;

		&:hover {
			color: #dc2626;
		}
	}

	&__text {
		margin-bottom: 16px;
		line-height: 1.6;
	}

	&__image {
		max-width: 200px;
		border-radius: 8px;
		margin-bottom: 16px;
	}

	&__footer {
		display: flex;
		gap: 16px;
	}

	&__reply-button,
	&__collapse-button {
		display: flex;
		align-items: center;
		gap: 4px;
		color: #f9b403;
		background: none;
		border: none;
		cursor: pointer;
		font-weight: 500;
		padding: 0;

		&:hover {
			color: #e6a503;
		}
	}

	&__reply-form {
		margin-top: 16px;

		textarea {
			width: 100%;
			padding: 8px;
			border: 1px solid #e5e7eb;
			border-radius: 8px;
			resize: vertical;
			min-height: 80px;
			margin-bottom: 8px;
			box-sizing: border-box;

			&:focus {
				outline: none;
				border-color: #f9b403;
				box-shadow: 0 0 0 2px rgba(249, 180, 3, 0.1);
			}
		}

		&-actions {
			display: flex;
			justify-content: flex-end;
			gap: 8px;
		}

		&-cancel {
			background: none;
			color: #4b5563;
			border: none;
			padding: 6px 12px;
			cursor: pointer;

			&:hover {
				color: #1f2937;
			}
		}

		&-submit {
			background: linear-gradient(to bottom, #ffd05c, #f9b403);
			color: #1f2937;
			border: none;
			padding: 10px 16px;
			border-radius: 9999px;
			cursor: pointer;

			&:hover {
				opacity: 0.9;
			}
		}
	}

	&__replies {
		margin-top: 16px;
	}
}
