@import "themes/constants.module.scss";

.root {
	margin: 0;
	padding: 0;

	&.font-primary {
		font-family: var(--font-family-primary);
	}

	&.font-secondary {
		font-family: var(--font-family-secondary);
	}

	&.weight-regular {
		font-weight: var(--font-weight-regular);
	}

	&.weight-medium {
		font-weight: var(--font-weight-medium);
	}

	&.weight-bold {
		font-weight: var(--font-weight-bold);
	}

	&.color-primary {
		color: var(--Primary-500);
	}

	&.color-secondary {
		color: var(--Secondary-500);
	}

	&.color-success {
		color: var(--Success-500);
	}

	&.color-warning {
		color: var(--Warning-500);
	}

	&.color-error {
		color: var(--Error-500);
	}

	&.color-neutral {
		color: var(--color-generic-black);
	}
	&.color-white {
		color: var(--color-generic-white);
	}
	&.hide {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		line-clamp: 3;
		overflow: hidden;
		text-overflow: ellipsis;
		max-height: 72px;
	}
}

.h1 {
	font-size: 48px;
	@media screen and (max-width: $screen-sm) {
		font-size: 40px;
	}
}

.h2 {
	font-size: 40px;
	@media screen and (max-width: $screen-sm) {
		font-size: 32px;
	}
}

.h3 {
	font-size: 32px;
	@media screen and (max-width: $screen-sm) {
		font-size: 24px;
	}
}

.h4 {
	font-size: 24px;
	@media screen and (max-width: $screen-sm) {
		font-size: 20px;
	}
}

.h5 {
	font-size: 18px;
	@media screen and (max-width: $screen-sm) {
		font-size: 16px;
	}
}

.p,
.span {
	&.size-xlarge {
		font-size: 20px;
		line-height: 28px;
	}

	&.size-large {
		font-size: 18px;
		line-height: 25px;
	}

	&.size-medium {
		font-size: 16px;
		line-height: 22px;
	}

	&.size-small {
		font-size: 14px;
		line-height: 19px;
	}

	&.size-xsmall {
		font-size: 12px;
		line-height: 16px;
	}
}
