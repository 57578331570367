.root {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	.input-container{
		display: flex;
		align-items: center;
		gap: 8px;
	}
	.errors-container{
		display: flex;
		flex-direction: column;
	}
}
