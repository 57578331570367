.root {
	display: flex;
	align-items: center;
	position: relative;
}

.title-container {
	position: absolute;
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%);
	margin-bottom: 8px;
	white-space: nowrap;
	z-index: 1;
	transition: opacity 0.2s ease;

	/* Arrow Triangle */
	&::after {
		content: '';
		position: absolute;
		bottom: -8px; /* Slight overlap with the title container */
		left: 50%;
		transform: translateX(-50%);
		width: 0;
		height: 0;
		border-top: 12px solid var(--Neutral-950); /* Triangle color matches background */
		border-right: 11px solid transparent;
		border-left: 11px solid transparent;
	}

	.title {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 4px 14px;
		background-color: var(--Neutral-950);
		color: var(--Neutral-50);
		border-radius: 6px;
	}
}

.no-arrow {
	&::after {
		content: none;
	}
}

.hidden {
	opacity: 0;
	pointer-events: none;
}
