.root {
	padding: 4px 12px;
	border-radius: 32px;
	background-color: #e5e7eb;
	width: fit-content;
	cursor: pointer;
}

.selected {
	background-color: var(--Primary-500);
}
