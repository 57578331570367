.root {
	display: flex;
	gap: 8px;
	align-items: center;

	.checkboxes-container {
		.original-checkbox {
			appearance: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			opacity: 0;
			position: absolute;
		}

		.custom-checkbox {
			display: flex;
			align-items: center;
			justify-content: center;
			color: white;
			margin: 2px 0;
			padding: 0;
			width: 20px;
			height: 20px;
			border-radius: 4px;
			border: 2px solid var(--text-secondary);
			user-select: none;
			cursor: pointer;

			&.checked {
				background-color: #4b4efc;
				border-color: #4b4efc;
			}
		}
	}

	.label-container {
		cursor: pointer;
	}

	.sublabel {
		color: #4B5563;
	}
}
