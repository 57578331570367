@import "themes/constants.module.scss";

.root {
	display: flex;
	flex-direction: column;
	gap: 32px;

	.title-container {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	.form-container {
		display: flex;
		flex-direction: column;
		gap: 16px;

		.input-name-container {
			display: flex;
			gap: 16px;
		}

		.input {
			width: 100%;
		}

		select {
			padding: 12px;
		}

		.input-content {
			border: 1px solid var(--color-generic-black);
		}

		.submit-container {
			display: flex;
			flex-direction: column;
			gap: 40px;

			.buttons-container {
				display: flex;
				flex-direction: column;
				gap: 16px;
			}

			.login-redirect-container {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 6px;
			}
		}
	}
}
