@import "themes/constants.module.scss";

.root {
	display: flex;
	align-items: center;
	gap: 32px;

	.menu-container {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		width: 100%;

		.menu {
			position: absolute;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			top: 0;
			right: 50px;
			border-radius: 8px 8px 0 0;
			background: var(--color-background-200);
			box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
			z-index: 2;
			width: 266px;
			padding: 4px 0 4px 0;

			@media screen and (max-width: $screen-sm) {
				position: fixed;
				justify-content: flex-start;
				left: 0;
				width: 100%;
				top: calc(100% - 469px);
				height: 469px;
			}

			.row {
				display: flex;
				padding: 14px 16px;
			}
		}
	}
}

.background {
	display: none;

	@media screen and (max-width: $screen-sm) {
		&.is-open {
			display: block;
		}
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.7);
		z-index: 1;
	}
}
