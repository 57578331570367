@import "themes/constants.module.scss";

.root {
	display: flex;
	flex-direction: column;
	gap: 16px;

	.content {
		display: flex;
		gap: 16px;
	}

	.button {
		&:hover {
			.button-text {
				color: var(--color-generic-black);
			}
		}
		.button-text {
			color: var(--color-generic-white);
			@media screen and (max-width: $screen-lg) {
				display: none;
			}
		}
	}
}
