@import "themes/constants.module.scss";

.root {
	padding: 64px;
	display: flex;
	flex-direction: column;
	gap: 56px;

	@media screen and (max-width: $screen-md) {
		padding: 0 0 32px 0;
	}

	.pagination {
		margin: 0 auto;
	}

	.title {
		@media screen and (max-width: $screen-sm) {
			padding: 32px 32px 0 32px;
		}
	}
}
