@import "themes/constants.module.scss";

.root {
	min-height: 100vh;
	display: flex;

	@media (max-width: $screen-md) {
		flex-direction: column-reverse;
	}

	.content {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 24px 64px;

		@media (max-width: $screen-md) {
			padding: 24px 16px;
		}

		.main {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 auto;
			max-width: 550px;
			height: 100%;
		}
	}

	.aside {
		position: relative;
		flex: 1;
		background-color: #fbf9f3;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		@media screen and (max-width: $screen-md) {
			height: 200px;
			justify-content: center;
			flex: unset;
		}

		@media screen and (max-width: $screen-xs) {
			height: auto;
			background-color: transparent;
			align-items: start;
		}

		.image {
			position: absolute;
			z-index: 0;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			object-fit: cover;

			@media screen and (max-width: $screen-xs) {
				display: none;
			}
		}

		.logo {
			z-index: 1;
			width: 500px;
			height: 80px;
			object-fit: contain;

			@media screen and (max-width: $screen-md) {
				width: 300px;
			}

			@media screen and (max-width: $screen-xs) {
				width: 154px;
				height: 25px;
				margin-top: 24px;
				margin-left: 16px;
			}
		}

		.footer {
			z-index: 1;
			margin: 12px;

			@media screen and (max-width: $screen-md) {
				display: none;
			}
		}
	}
}
