.root {
	display: flex;
	align-items: center;
	gap: 32px;

	.button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 32px;
		height: 32px;
		border-radius: 4px;
		border: 1px solid #d1d5db;
		background: #fff;
		transition: background-color 0.3s;
		cursor: pointer;

		&:hover {
			background-color: var(--Primary-500);
			border-color: var(--Primary-500);
		}

		.icon {
			width: 20px;
			height: 20px;
		}
	}

	.ellipsis {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 32px;
		height: 32px;
		border-radius: 4px;
		border: 1px solid #d1d5db;
		background: #fff;
		transition: background-color 0.3s;
		cursor: pointer;
	}

	.page-container {
		display: flex;
		align-items: center;
		gap: 8px;

		.active {
			background-color: var(--Primary-500);
			border-color: var(--Primary-500);
		}
	}
}
