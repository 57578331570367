@import "themes/constants.module.scss";

.option-item {
	width: 100%;
	display: flex;
	gap: 8px;

	cursor: pointer;

	.menu-container {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		width: 218px;

		.button {
			display: flex;
			align-items: center;
			gap: 8px;
		}

		.icon {
			width: 20px;
			height: 20px;
		}
	}
}

.icons {
	width: 20px;
	height: 20px;
}

.menu {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: absolute;
	right: calc(100% + 30px);
	top: calc(100% - 180px);
	padding: 16px 32px 32px 32px;
	border-radius: 0 0 8px 8px;
	background: var(--color-background-200);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	z-index: 1;
	width: 266px;
	height: 248px;

	@media screen and (max-width: $screen-sm) {
		right: unset;
		top: unset;
	}

	.row {
		display: flex;
		gap: 16px;
	}
}
