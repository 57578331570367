@import "themes/constants.module.scss";

.root {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 24px;

	.project-call-container {
		display: flex;
		justify-content: center;
		gap: 24px;
		@media screen and (max-width: $screen-md) {
			flex-direction: column;
			align-items: center;
		}
	}

	.description {
		display: grid;
		grid-gap: 24px;
		grid-template-rows: auto;
		grid-template-columns: repeat(2, 1fr);
		max-width: 1440px;

		@media screen and (max-width: $screen-md) {
			flex-direction: column;
			display: flex;
		}
	}
}
