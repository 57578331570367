@import "themes/constants.module.scss";

.root {
	padding: 80px 64px;
	display: flex;
	justify-content: center;
	align-items: center;

	.profile-container {
		display: flex;
		flex-direction: column;
		gap: 48px;

		.title-container {
			display: flex;
			flex-direction: column;
			gap: 24px;
		}

		.form-container {
			display: flex;
			flex-direction: column;
			gap: 32px;

			.all-inputs {
				display: flex;
				flex-direction: column;
				gap: 24px;

				.inputs-container {
					display: flex;
					align-items: flex-start;
					gap: 24px;
				}

				.input {
					width: 100%;
				}
				.input-content {
					border: 1px solid var(--color-generic-black);
				}
			}

			.submit-container {
				display: flex;
				flex-direction: column;
				gap: 40px;

				.buttons-container {
					display: flex;
					flex-direction: column;
					gap: 16px;
				}
			}
		}
	}
}
