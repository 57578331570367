@import "themes/constants.module.scss";

.root {
	display: none;
	position: relative;
	z-index: 5;

	@media screen and (max-width: $screen-md) {
		display: block;
	}

	.bars-icon {
		width: 32px;
		height: 32px;
		cursor: pointer;
	}

	.menu {
		padding: 16px 32px 32px 32px;
		display: none;
		position: absolute;
		top: 52px;
		width: 266px;
		right: 0;
		border-radius: 0 0 8px 8px;
		background: var(--color-background-200);
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
		z-index: 7;
		overflow: hidden;

		@media screen and (max-width: $screen-md) {
			display: flex;
			flex-direction: column;
			gap: 24px;
		}

		@media screen and (max-width: $screen-sm) {
			position: fixed;
			left: 0;
			width: 100%;
			height: calc(100% - 52px);
		}

		.logout-icon {
			width: 20px;
			height: 20px;
		}

		.link {
			display: flex;
			justify-content: space-between;
			align-items: center;
			text-decoration: none;
			padding: 8px 0;
			white-space: nowrap;
			cursor: pointer;
		}

		.not-connected-header-container {
			display: none;
			position: absolute;
			bottom: 0;
			left: 0;
			padding: 32px 24px;
			background: var(--color-background-200);
			width: 100%;
			box-shadow: 0px -4px 20px 0px rgba(159, 159, 159, 0.15);

			@media screen and (max-width: $screen-sm) {
				display: flex;
			}

			.not-connected-header {
				width: 100%;

				@media screen and (max-width: $screen-xs) {
					flex-direction: column;
				}
			}
		}

		.connected-header {
			display: none;

			@media screen and (max-width: $screen-sm) {
				display: flex;
				flex-direction: column;
				gap: 24px;
			}
		}
	}
}
