@import "themes/constants.module.scss";

.root {
	display: flex;
	padding: 112px 64px;
	gap: 80px;

	@media screen and (max-width: $screen-md) {
		padding: 80px 24px;
		flex-direction: column;
		align-items: center;
		gap: 32px;
	}

	.content {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 32px;

		.title-container {
			display: flex;
			flex-direction: column;
			gap: 24px;
		}

		.form-container {
			display: flex;
			flex-direction: column;
			gap: 32px;

			.input-container {
				display: flex;
				flex-direction: column;
				gap: 24px;

				.inputs {
					display: flex;
					gap: 16px;
				}

				.input {
					width: 100%;
				}
				.input-content {
					border: 1px solid var(--color-generic-black);
				}

				.textarea-container {
					width: 100%;

					textarea {
						resize: vertical;
					}
				}
			}
		}
	}

	.design {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;

		.image {
			width: 329px;
			height: 358px;
		}

		@media screen and (max-width: $screen-md) {
			display: none;
		}
	}
}
