.root {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background: rgba(0, 0, 0, 0.10);
    
    .backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(5px);
    }

    .content {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        width: 100%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        pointer-events: none;
        
        .poster {
            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .close-button {
        position: absolute;
        top: 0;
        right: 0;
        margin: 35px;
    }
}
