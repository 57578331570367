@import "themes/constants.module.scss";

.root {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-grow: 1;

	@media screen and (max-width: $screen-md) {
		align-items: flex-start;
	}

	.link {
		margin-top: 16px;
		text-decoration: none;
	}
}
