.root {
	display: flex;
	align-items: center;
	gap: 32px;

	.menu-container {
		position: relative;
		display: flex;
		align-items: center;
		gap: 4px;
		cursor: pointer;

		.avatar-container {
			width: 50px;
			height: 50px;
			border-radius: 50%;
			overflow: hidden;

			.avatar {
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}

		.icon {
			width: 20px;
			height: 20px;
		}

		.menu {
			display: flex;
			flex-direction: column;
			gap: 24px;
			position: absolute;
			top: calc(100% + 16px); // 16px is the padding of the header
			right: 0;
			padding: 16px 32px 32px 32px;
			border-radius: 0 0 8px 8px;
			background: var(--color-background-200);
			box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

			.link {
				text-decoration: none;

				.whitespace-wrap {
					white-space: nowrap;
				}
			}
		}
	}
}
