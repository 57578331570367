@import "themes/constants.module.scss";

.root {
	position: sticky;
	top: 0;
	z-index: 5;
	background: var(--color-background-200);

	.content {
		display: flex;
		flex-direction: column;
		justify-content: center;

		nav {
			padding: 16px 0;
			margin: 0 64px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 32px;
			width: 100%;
			max-width: -webkit-fill-available;

			@media screen and (max-width: $screen-xl) {
				padding: 16px 24px;
				margin: auto;
			}

			@media screen and (max-width: $screen-sm) {
				padding: 16px;
			}

			.logo-container {
				display: flex;
				align-items: center;

				.logo {
					width: 192px;
					height: 32px;
					object-fit: cover;

					@media screen and (max-width: 1200px) {
						width: 26px;
						object-position: 0;
					}

					@media screen and (max-width: $screen-md) {
						width: 167.863px;
						height: 28px;
					}

					@media screen and (max-width: $screen-sm) {
						width: 143.882px;
						height: 24px;
					}
				}
			}

			.links-container {
				display: flex;
				align-items: center;
				gap: 32px;

				.link {
					white-space: nowrap;


					text-decoration: none;
					text-align: center;

					@media screen and (max-width: $screen-md) {
						display: none;
					}
				}

				.extra-header {
					@media screen and (max-width: $screen-sm) {
						display: none;
					}
				}
			}
		}
	}
}
