@import "themes/constants.module.scss";

.root {
	display: flex;
	align-items: center;
	gap: 32px;

	.menu-container {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		width: 100%;

		.icon {
			width: 20px;
			height: 20px;
		}

		.menu {
			display: flex;
			flex-direction: column;
			gap: 24px;
			position: absolute;
			padding: 16px 32px 32px 32px;
			border-radius: 0 0 8px 8px;
			background: var(--color-background-200);
			box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
			z-index: 2;

			.row {
				display: flex;
				gap: 16px;
			}
		}

		.fixed {
			@media screen and (max-width: $screen-sm) {
				position: fixed;
				bottom: 0;
				left: 0;
				width: 100%;
				padding-bottom: 100px;
				height: auto;
				border-radius: 8px;
			}
		}
	}
}

.background {
	display: none;

	@media screen and (max-width: $screen-sm) {
		&.is-open {
			display: block;
		}
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.7);
		z-index: 1;
	}
}
