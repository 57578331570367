@import "themes/constants.module.scss";

.root {
	display: flex;
	flex-direction: column;
	gap: 64px;
	padding: 64px;

	@media screen and (max-width: $screen-md) {
		padding: 64px 24px;
		gap: 48px;
	}

	@media screen and (max-width: $screen-sm) {
		padding: 48px 16px;
		gap: 32px;
	}

	.header-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 40px;
		max-width: 1200px;
		width: 100%;
		margin: 0 auto;

		@media screen and (max-width: $screen-sm) {
			flex-direction: column-reverse;
		}

		.poster-container {
			.poster {
				border-radius: 16px;
				max-width: 465px;
				max-height: 622px;

				@media screen and (max-width: $screen-md) {
					min-width: 262px;
				}
			}
		}

		.content-container {
			display: flex;
			flex-direction: column;
			gap: 24px;
			max-width: 571px;

			.header {
				display: flex;
				flex-direction: column;
				gap: 16px;

				.author {
					display: flex;
					align-items: center;
					gap: 16px;

					.avatar {
						width: 40px;
						height: 40px;
						border-radius: 50%;
						overflow: hidden;
					}
				}

				.infos {
					display: flex;
					gap: 16px;
					justify-content: space-between;
					flex-wrap: wrap;

					.info {
						color: #4b5563;
					}
				}

				.box {
					width: fit-content;
					padding: 12px 16px;
					border: 1px solid var(--Secondary-500);
					border-radius: 4px;
				}
			}

			.content {
				display: flex;
				flex-direction: column;
				gap: 8px;
				word-wrap: break-word;

				.disabled {
					display: none;
				}
			}
		}
	}
}
