@import "themes/constants.module.scss";

.root {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 40px;
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;

	@media screen and (max-width: $screen-sm) {
		flex-direction: column-reverse;
	}

	.poster-container {
		.poster {
			max-width: 465px;
			max-height: 622px;

			
			border-radius: 16px;


			@media screen and (max-width: $screen-md) {
				min-width: 262px;
			}

			@media screen and (max-width: $screen-sm) {
				max-height: 297px;
				border-radius: 8px;
				min-width: unset;
			}

			
		}
	}

	.content-container {
		display: flex;
		flex-direction: column;
		gap: 24px;
		max-width: 571px;

		.header {
			display: flex;
			flex-direction: column;
			gap: 16px;

			.author {
				display: flex;
				align-items: center;
				gap: 16px;

				.avatar {
					width: 40px;
					height: 40px;
					border-radius: 50%;
					overflow: hidden;
				}
			}

			.infos {
				display: flex;
				gap: 16px;
				justify-content: space-between;
				flex-wrap: wrap;

				.info {
					color: #4b5563;
				}
			}

			.box {
				width: fit-content;
				padding: 12px 16px;
				border: 1px solid var(--Secondary-500);
				border-radius: 4px;
			}
		}

		.content {
			display: flex;
			flex-direction: column;
			gap: 24px;

			.title-container {
				display: flex;
				flex-direction: column;
				gap: 8px;

				.subtitle {
					color: #4b5563;
				}
			}
			.disabled {
				display: none;
			}
		}
	}

	.link {
		text-decoration: none;
	}
}
