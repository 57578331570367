.root {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 25px;
	padding: 4px 8px;
	border-radius: 24px;
	background-color: var(--Neutral-200);
    text-wrap: nowrap;
}
