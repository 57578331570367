@import "themes/constants.module.scss";
@import "themes/variables.scss";
@import "themes/fonts.scss";

:root {
	--color-generic-white: #{$color-generic-white};
	--color-generic-black: #{$color-generic-black};

	--color-background-100: #{$color-background-100};
	--color-background-200: #{$color-background-200};
	--color-background-300: #{$color-background-300};

	--font-family-primary: #{$font-family-primary};
	--font-family-secondary: #{$font-family-secondary};

	--text-primary: #{$text-primary};
	--text-secondary: #{$text-secondary};
	--text-inverted: #{$text-inverted};

	--font-weight-bold: #{$font-weight-bold};
	--font-weight-medium: #{$font-weight-medium};
	--font-weight-regular: #{$font-weight-regular};

	--Primary-50: #{$Primary-50};
	--Primary-100: #{$Primary-100};
	--Primary-200: #{$Primary-200};
	--Primary-300: #{$Primary-300};
	--Primary-400: #{$Primary-400};
	--Primary-500: #{$Primary-500};
	--Primary-600: #{$Primary-600};
	--Primary-700: #{$Primary-700};
	--Primary-800: #{$Primary-800};
	--Primary-850: #{$Primary-850};
	--Primary-900: #{$Primary-900};
	--Primary-950: #{$Primary-950};

	--Secondary-50: #{$Secondary-50};
	--Secondary-100: #{$Secondary-100};
	--Secondary-200: #{$Secondary-200};
	--Secondary-300: #{$Secondary-300};
	--Secondary-400: #{$Secondary-400};
	--Secondary-500: #{$Secondary-500};
	--Secondary-600: #{$Secondary-600};
	--Secondary-700: #{$Secondary-700};
	--Secondary-800: #{$Secondary-800};
	--Secondary-850: #{$Secondary-850};
	--Secondary-900: #{$Secondary-900};
	--Secondary-950: #{$Secondary-950};

	--Neutral-50: #{$Neutral-50};
	--Neutral-100: #{$Neutral-100};
	--Neutral-200: #{$Neutral-200};
	--Neutral-300: #{$Neutral-300};
	--Neutral-400: #{$Neutral-400};
	--Neutral-500: #{$Neutral-500};
	--Neutral-600: #{$Neutral-600};
	--Neutral-700: #{$Neutral-700};
	--Neutral-800: #{$Neutral-800};
	--Neutral-900: #{$Neutral-900};
	--Neutral-950: #{$Neutral-950};

	--Success-50: #{$Success-50};
	--Success-100: #{$Success-100};
	--Success-200: #{$Success-200};
	--Success-300: #{$Success-300};
	--Success-400: #{$Success-400};
	--Success-500: #{$Success-500};
	--Success-600: #{$Success-600};
	--Success-700: #{$Success-700};
	--Success-800: #{$Success-800};
	--Success-900: #{$Success-900};
	--Success-950: #{$Success-950};

	--Warning-50: #{$Warning-50};
	--Warning-100: #{$Warning-100};
	--Warning-200: #{$Warning-200};
	--Warning-300: #{$Warning-300};
	--Warning-400: #{$Warning-400};
	--Warning-500: #{$Warning-500};
	--Warning-600: #{$Warning-600};
	--Warning-700: #{$Warning-700};
	--Warning-800: #{$Warning-800};
	--Warning-900: #{$Warning-900};
	--Warning-950: #{$Warning-950};

	--Error-50: #{$Error-50};
	--Error-100: #{$Error-100};
	--Error-200: #{$Error-200};
	--Error-300: #{$Error-300};
	--Error-400: #{$Error-400};
	--Error-500: #{$Error-500};
	--Error-600: #{$Error-600};
	--Error-700: #{$Error-700};
	--Error-800: #{$Error-800};
	--Error-900: #{$Error-900};
	--Error-950: #{$Error-950};
}

[theme-mode] {
	--color-generic-white: #{$color-generic-white};
	--color-generic-black: #{$color-generic-black};
}
