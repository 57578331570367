.root {
	position: relative;
	width: fit-content;
	border: 0;
	border-radius: 300px;
	background: transparent;
	font-family: var(--font-family-primary);
	font-weight: var(--font-weight-medium);
	transition: background-color 150ms ease-out;
	white-space: nowrap;
	user-select: none;
	cursor: pointer;
	outline: none;

	&:disabled {
		pointer-events: none;
		opacity: 0.3;
	}

	.content {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;
	}

	.loader-container {
		display: flex;
		align-items: center;
		justify-content: center;

		.loader {
			min-width: 24px;
			max-width: 24px;
			min-height: 24px;
			max-height: 24px;

			path {
				stroke: none !important;
			}
		}
	}
}

.root.large {
	height: 48px;
	font-size: 18px;
	padding: 12px 16px;
}

.root.medium {
	height: 40px;
	font-size: 16px;
	padding: 8px 12px;
}

.root.small {
	height: 32px;
	font-size: 14px;
	padding: 6px 12px;
}

.root.xsmall {
	height: 28px;
	font-size: 14px;
	padding: 4px 10px;
}

.root.fullwidth {
	width: 100%;
}

/* CONTAINED BUTTONS */
/* PRIMARY */
.root.contained.primary {
	background: linear-gradient(180deg, #ffd05c 0%, #ffdc85 0.01%, #ffc433 100%);
	color: var(--text-primary);
}
.root.contained.primary path {
	stroke: var(--text-primary);
}

.root.contained.primary:hover {
	background: linear-gradient(180deg, #ffc433 0%, #ce9e29 100%);
}

.root.contained.primary:active {
	background: linear-gradient(180deg, #ce9e29 0%, #ce9e29 100%);
}

.root.contained.secondary {
	background-color: var(--Secondary-100);
	border: 1px solid var(--Secondary-500);
	color: var(--text-primary);

	&:disabled {
		pointer-events: none;
		opacity: 1;
	}
}
.root.contained.secondary path {
	stroke: var(--text-primary);
}

.root.contained.secondary:hover {
	background-color: var(--Secondary-50);
}

.root.contained.secondary:active {
	background-color: var(--Secondary-100);
}
/* NEUTRAL */
.root.contained.neutral {
	background-color: var(--Neutral-900);
	border: 1px solid var(--Neutral-500);
	color: var(--text-inverted);

	&:disabled {
		pointer-events: none;
		opacity: 1;
	}
}
.root.contained.neutral path {
	stroke: var(--text-primary);
}

.root.contained.neutral:hover {
	background-color: var(--Neutral-50);
	color: var(--Neutral-900);
}

.root.contained.neutral:active {
	background-color: var(--Neutral-100);
}
/* DESTRUCTIVE */
.root.contained.destructive {
	background-color: var(--Error-500);
	color: #fff;
}
.root.contained.destructive path {
	stroke: #fff;
}

.root.contained.destructive:hover {
	background-color: var(--Error-600);
}

.root.contained.destructive:active {
	background-color: var(--Error-700);
}

/* OUTLINED BUTTONS */
/* PRIMARY */
.root.outlined.primary {
	border: 1px solid var(--Primary-500);
	color: var(--text-primary);
}
.root.outlined.primary path {
	stroke: var(--text-primary);
}

.root.outlined.primary:hover {
	background-color: var(--Primary-50);
}

.root.outlined.primary:active {
	background-color: var(--Primary-100);
}

/* DESTRUCTIVE */
.root.outlined.destructive {
	border: 1px solid var(--Error-500);
	color: var(--Error-500);
}
.root.outlined.destructive path {
	stroke: var(--Error-500);
}

.root.outlined.destructive:hover {
	border: 1px solid var(--Error-600);
	color: var(--Error-600);
	background-color: var(--Error-50);
}

.root.outlined.destructive:active {
	border: 1px solid var(--Error-600);
	color: var(--Error-600);
	background-color: var(--Error-100);
}

/* TEXT BUTTONS */
/* PRIMARY */
.root.text {
	padding: 0;
	margin: 4px 0;
	min-width: unset;
	height: auto;
}

.root.text.primary {
	border-radius: unset;
	color: var(--text-primary);
	border-bottom: 1px solid var(--text-primary);
}
.root.text.primary path {
	stroke: var(--text-primary);
}

.root.text.primary:hover {
	color: var(--text-secondary);
	border-bottom: 1px solid var(--text-secondary);
}
.root.text.primary:hover path {
	stroke: var(--text-secondary);
}

.root.text.primary:active {
	color: #9ca3af;
	border-bottom: 1px solid #9ca3af;
}
.root.text.primary:active path {
	stroke: #9ca3af;
}

/* NEUTRAL */
.root.text.neutral {
	border-radius: unset;
	color: #6b7280;
	border-bottom: 1px solid #6b7280;
}
.root.text.neutral path {
	stroke: #6b7280;
}

.root.text.neutral:hover {
	color: #9ca3af;
	border-bottom: 1px solid #9ca3af;
}
.root.text.neutral:hover path {
	stroke: #9ca3af;
}

.root.text.neutral:active {
	color: #d1d5db;
	border-bottom: 1px solid #d1d5db;
}
.root.text.neutral:active path {
	stroke: #d1d5db;
}

/* SELECTOR BUTTONS */
/* PRIMARY */
.root.selector {
	padding: 8px 16px;
	min-width: unset;
	height: auto;

	&.selected {
		border-radius: 0;
		border-bottom: 2px solid;
	}
}

// .root.text.primary {
// 	border-radius: unset;
// 	color: var(--text-primary);
// 	border-bottom: 1px solid var(--text-primary);
// }
// .root.text.primary path {
// 	stroke: var(--text-primary);
// }

// .root.text.primary:hover {
// 	color: var(--text-secondary);
// 	border-bottom: 1px solid var(--text-secondary);
// }
// .root.text.primary:hover path {
// 	stroke: var(--text-secondary);
// }

// .root.text.primary:active {
// 	color: #9ca3af;
// 	border-bottom: 1px solid #9ca3af;
// }
// .root.text.primary:active path {
// 	stroke: #9ca3af;
// }

// /* NEUTRAL */
// .root.text.neutral {
// 	border-radius: unset;
// 	color: #6b7280;
// 	border-bottom: 1px solid #6b7280;
// }
// .root.text.neutral path {
// 	stroke: #6b7280;
// }

// .root.text.neutral:hover {
// 	color: var(--color-generic-black);
// 	border-bottom: 1px solid #9ca3af;
// }
// .root.text.neutral:hover path {
// 	stroke: #9ca3af;
// }

// .root.text.neutral:active {
// 	color: #d1d5db;
// 	border-bottom: 1px solid #d1d5db;
// }
// .root.text.neutral:active path {
// 	stroke: #d1d5db;
// }




.root svg {
	min-width: 24px;
	max-width: 24px;
	min-height: 24px;
	max-height: 24px;
}
