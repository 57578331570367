@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.fade-in {
	animation: fadeIn 800ms;
}

img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	// cursor: pointer;
}
