@import "themes/constants.module.scss";

.root {
	background-color: var(--color-background-300);
	min-height: 100px;
	display: flex;
	align-items: center;
	gap: 16px;
	z-index: 3;

	@media screen and (min-width: $screen-sm) {
		padding: 16px 64px;
	}
	padding: 16px 24px;

	.close-container {
		display: flex;
		justify-self: flex-end;

		@media screen and (max-width: $screen-sm) {
			position: sticky;
			top: 0;
		}

		.close-icon {
			width: 24px;
			height: 24px;
			cursor: pointer;
		}
	}
}
