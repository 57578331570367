@import "themes/constants.module.scss";

.poster-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    .poster {
        width: 250px;
        height: 340px;
        border-radius: 16px;
    }

    .missing-poster {
        display: flex;
        flex-direction: column;
        gap: 12px;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 12px;
        color: var(--Secondary-500);
        width: 250px;
        height: 340px;
        border: 1px solid var(--Secondary-500);
    }

    .loading-poster {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 12px;
        width: 250px;
        height: 340px;
    }
}
