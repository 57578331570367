@import "themes/constants.module.scss";

.root {
	border-collapse: collapse;
	width: 100%;

	.thead {
		border-bottom: 1px solid black;
	}

	.th {
		text-align: left;
		min-width: 160px;
		width: 160px;
		padding: 16px 24px;
		@media screen and (max-width: $screen-lg) {
			display: none;
		}
	}
}
