.root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	.main {
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		overflow: hidden;
	}
}
