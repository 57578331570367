@import "themes/constants.module.scss";

.root {
	padding: 80px 64px;
	display: flex;
	justify-content: center;
	align-items: center;

	.profile-container {
		display: flex;
		flex-direction: column;
		gap: 48px;

		.header {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 12px;
			cursor: pointer;

			.input-file {
				display: none;
			}

			.avatar {
				width: 150px;
				height: 150px;
				border-radius: 50%;
				object-fit: cover;
				overflow: hidden;
			}
		}

		.form-container {
			display: flex;
			flex-direction: column;
			gap: 32px;

			.all-inputs {
				display: flex;
				flex-direction: column;
				gap: 24px;

				.inputs-container {
					display: flex;
					align-items: center;
					gap: 24px;
				}

				.input {
					width: 100%;
				}

				.input-content {
					border: 1px solid var(--color-generic-black);
				}
			}

			.buttons-container {
				display: flex;
				justify-content: space-between;

				@media screen and (max-width: $screen-xs) {
					flex-direction: column-reverse;
					text-align: center;
					gap: 24px;
				}
			}
		}
	}
}
